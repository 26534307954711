/**
 * Top menu
 */
.navbar-height {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  border-color: #000066;
  padding-left: 0 !important;
  border-style: solid;
  border-width: 0 0 1px 4px;
}
.navbar .navbar-brand {
  margin-right: 6px;
}
.navbar-brand img {
  width: 3rem;
  height: 3rem;
}
.nav-link {
  color: #000044;
  border-bottom: 1px solid transparent;
}
.nav-link.active,
.nav-link:hover,
.nav-link:active,
.nav-link:focus {
  border-bottom: 1px solid #000066;
}
/**
 * Page layout
 */
.page-col-left,
.page-col-right {
  position: absolute;
  top: 0;
  bottom: 0;
}
.page-col-left {
  left: 0;
  right: 50%;
}
.page-col-right {
  left: 50%;
  right: 0;
  overflow-y: scroll;
}
article {
  max-width: 50rem;
  padding: 1rem;
  margin: 0 auto;
  font-size: 1.2rem;
}
article h1,
article h2 {
  font-weight: bold;
}
article h1 {
  font-size: 1.75rem;
}
article h2 {
  font-size: 1.2rem;
  margin-top: 1rem;
}
article .alert {
  margin-top: 1rem;
}
/**
 * Map and controls
 */
.map-height {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  bottom: 0;
}
.map.mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.custom-map-control {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
  border-radius: 2px;
  padding: 5px;
  max-height: calc(100vh - 7rem);
  overflow-y: scroll;
}
.custom-map-control.top-left {
  top: 10px;
  left: 10px;
  max-width: 18rem;
}
.custom-map-control small {
  display: block;
  line-height: 1.2;
  margin: 0.2rem 0;
}
.custom-map-control.top-right {
  top: 10px;
  right: 50px;
}
.custom-map-control.bottom-left {
  bottom: 10px;
  left: 10px;
}
.custom-map-control.bottom-right {
  bottom: 10px;
  right: 10px;
}
.custom-map-control .h4 {
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 2px;
  border-bottom: 2px solid #000066;
}
.custom-map-control .h5,
.custom-map-control summary {
  font-weight: bold;
  font-size: 1rem;
}
.custom-map-control .h5 {
  margin: 0.5rem 0 0.25rem;
}
.selected-feature {
  overflow: scroll;
  width: 40rem;
}
.selected-feature pre {
  overflow: visible;
  white-space: pre;
}
.position-control {
  font-size: 0.8rem;
}
/**
 * Legend
 */
.dot.line {
  border-radius: 0;
  height: 6px;
  width: 12px;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
      background-color: black;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  margin-bottom: 1px;
}
/**
 * Tooltip
 */
.tooltip-wrap {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  bottom: 0;
}
.tooltip-body {
  display: block;
  width: 300px;
  max-width: 100%;
  padding: 12px;
  background-color: #273d56;
  border-radius: 4px;
  box-shadow: 0 0 10px 2px rgba(31, 51, 73, 0.10);

  color: #fff;
  font-size: 12px;
  line-height: 18px;

  overflow: hidden;
}
.tooltip-triangle {
  display: block;
  max-width: 100%;
  width: 12px;
  height: 12px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 9px solid currentColor;
  font-size: 0;
  line-height: 0;
  color: #273d56;
  cursor: pointer;
}
